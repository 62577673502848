<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12 mb-4">
            <h5>Etes-vous allé(e) à l’école?</h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-check form-check-inline">
                <input
                  v-model="estInstruit"
                  class="form-check-input"
                  type="radio"
                  id="estInstruit1"
                  name="estInstruit"
                  :value="true"
                />
                <label class="form-check-label" for="estInstruit1">Oui</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  v-model="estInstruit"
                  class="form-check-input"
                  type="radio"
                  id="estInstruit2"
                  name="estInstruit"
                  :value="false"
                />
                <label class="form-check-label" for="estInstruit2">Non</label>
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div v-if="estInstruit" class="col-sm-12">
            <h5>Quel est votre dernier niveau d'étude?</h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <select
                  :value="editableItem.niveauInstruction"
                  @input="handleInput"
                  class="form-select"
                  id="niveauInstruction"
                  name="niveauInstruction"
                  aria-label="Sélection votre niveau d'étude"
                >
                  <option :value="n.id" v-for="n in niveauInstructions" :key="n.id">
                    {{ n.description }}
                  </option>
                </select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="niveauInstruction">Niveau d'étude</label>
              </div>
            </ValidationProvider>
          </div>

          <azoli v-if="estPeuOuPasInstruit" v-model="editableItem" />
          <handicap v-if="estInstruit != null" v-model="editableItem" />
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapGetters, mapActions } from "vuex";
import Azoli from "./Azoli.vue";
import Handicap from "./Handicap.vue";
export default {
  components: { Azoli, Handicap },
  props: ["value"],
  mixins: [setepperFormMixin],
  data() {
    return {
      estInstruit: this.value.estInstruit,
      editableItem: this.value,
    };
  },
  computed: {
    ...mapGetters({
      instructions: "instruction/instructions",
    }),
    niveauInstructions() {
      return this.editableItem.typeActivation != "CAS_SPECIAL"
        ? this.instructions
        : this.instructions.slice(0, 10);
    },
    estPeuOuPasInstruit() {
      return (
        this.estInstruit != null &&
        (!this.estInstruit ||
          (this.estInstruit &&
            this.editableItem.niveauInstruction != null &&
            this.editableItem.niveauInstruction <= 10))
      );
    },
  },
  watch: {
    estInstruit() {
      this.editableItem.estInstruit = this.estInstruit;
      this.$emit("input", this.editableItem);
      if (this.estInstruit !== null && !this.estInstruit) {
        this.editableItem.niveauInstruction = null;
      }
    },
  },
  created() {
    this.fetchInstructions();
  },
  methods: {
    ...mapActions({
      fetchInstructions: "instruction/fetchInstructions",
    }),
    handleInput(e) {
      if (e.target.name === "niveauInstruction") {
        this.editableItem[e.target.name] = parseInt(e.target.value);
      } else {
        this.editableItem[e.target.name] = e.target.value;
      }

      this.$emit("input", this.editableItem);
    },
  },
};
</script>

<style scoped></style>
